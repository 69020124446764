// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers

import { addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight auto
 */
export default function Phasio(props) {
    return (
        <iframe
            src="https://o.phas.io/o/Soch3D/checkout/upload"
            width="100%"
            height="1100"
            allow="fullscreen"
            frameBorder="0"
            referrerPolicy="origin-when-cross-origin"
        />
    )
}

// Styles are written in object syntax
// https://react.dev/reference/react-dom/components/common#usage
const boxStyle = {
    margin: 0,
    borderRadius: 0,
    width: "100%",
    height: "100%",
}
